.radioContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;

  & input[type='radio'] {
    /* margin-right: 10px;  */
    display: none;
  }

  /* Optional: Style the radio button appearance */
  & input[type='radio'] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #333;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }

  & input[type='radio']:checked {
    background-color: #333;
  }

  & label {
    display: flex;
    align-items: center;
  }
}

.customRadio {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  & svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.folder {
  padding-left: 30px;
  & label {
    cursor: pointer;
    & input[type='checkbox'] {
      /* margin-right: 10px;  */
      display: none;
    }
    & svg {
      margin-right: 10px;
    }
  }
}

.checked {
  display: none;
  /* fill: var(--marketplaceColor); */
}

.box {
  stroke: var(--colorGrey300);
}

.labelDisabled {
  cursor: not-allowed !important;
  color: #d9d9d9;
}
.options {
  @media (max-width: 767px) {
    padding: 6px 12px;
  }
}
.popupOpts {
  max-height: 476px;
  overflow-y: auto;
}
